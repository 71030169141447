import { getAnalytics, logEvent, setDefaultEventParameters, setUserProperties } from "firebase/analytics"
import { initializeApp } from "firebase/app"

import { orNull } from "~/helpers/primitives"
import { LocalStorageKeys } from "~/state/slices/selections"
import type { FirebaseAnalyticsDefaultEventParameters, FirebaseAnalyticsEvents } from "~/types/analytics"

// Ensure the relevant environment variables are set - https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const FIREBASE_API_KEY = import.meta.env.VITE_FIREBASE_API_KEY
if (FIREBASE_API_KEY === undefined || FIREBASE_API_KEY === "")
	throw new Error("The environment variable 'VITE_FIREBASE_API_KEY' is empty!")
const FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN
if (FIREBASE_AUTH_DOMAIN === undefined || FIREBASE_AUTH_DOMAIN === "")
	throw new Error("The environment variable 'VITE_FIREBASE_AUTH_DOMAIN' is empty!")
const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID
if (FIREBASE_PROJECT_ID === undefined || FIREBASE_PROJECT_ID === "")
	throw new Error("The environment variable 'VITE_FIREBASE_PROJECT_ID' is empty!")
const FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
if (FIREBASE_STORAGE_BUCKET === undefined || FIREBASE_STORAGE_BUCKET === "")
	throw new Error("The environment variable 'VITE_FIREBASE_STORAGE_BUCKET' is empty!")
const FIREBASE_MESSAGING_SENDER_ID = import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID
if (FIREBASE_MESSAGING_SENDER_ID === undefined || FIREBASE_MESSAGING_SENDER_ID === "")
	throw new Error("The environment variable 'VITE_FIREBASE_MESSAGING_SENDER_ID' is empty!")
const FIREBASE_APP_ID = import.meta.env.VITE_FIREBASE_APP_ID
if (FIREBASE_APP_ID === undefined || FIREBASE_APP_ID === "")
	throw new Error("The environment variable 'VITE_FIREBASE_APP_ID' is empty!")
const FIREBASE_MEASUREMENT_ID = import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
if (FIREBASE_MEASUREMENT_ID === undefined || FIREBASE_MEASUREMENT_ID === "")
	throw new Error("The environment variable 'VITE_FIREBASE_MEASUREMENT_ID' is empty!")

/**
 * Updates the default parameters sent with every Firebase/Google Analytics event.
 * @param newParameters Optional override of the current default parameters.
 * @example updateDefaultAnalyticsEventParameters()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.1.0
 */
export const updateFirebaseAnalyticsDefaultEventParameters = (
	newParameters?: Partial<FirebaseAnalyticsDefaultEventParameters>
): void => {
	if (!import.meta.env.PROD) return // Don't send analytics during development

	if (
		globalThis.semanticVersion === undefined ||
		globalThis.environmentName === undefined ||
		globalThis.launchedFrom === undefined
	)
		return

	const siteId = orNull(window.localStorage.getItem(LocalStorageKeys.CraftSiteSelectionJSON.valueOf()))
	const sectionId = orNull(window.localStorage.getItem(LocalStorageKeys.CraftSectionSelectionJSON.valueOf()))

	if (globalThis.defaultFirebaseAnalyticsEventParameters === null)
		globalThis.defaultFirebaseAnalyticsEventParameters = {
			// Static
			release: globalThis.semanticVersion,
			environment: globalThis.environmentName,
			from: globalThis.launchedFrom,

			// Legacy
			trust: null,
			role: null,

			// Modern
			craftSiteId: siteId !== null ? parseInt(siteId, 10) : null,
			craftSiteHandle: null,
			craftSiteName: null,
			craftSectionId: sectionId !== null ? parseInt(sectionId, 10) : null,
			craftSectionHandle: null,
			craftSectionName: null
		} satisfies Partial<FirebaseAnalyticsDefaultEventParameters>

	globalThis.defaultFirebaseAnalyticsEventParameters = {
		...globalThis.defaultFirebaseAnalyticsEventParameters,
		...newParameters,

		// Static
		release: globalThis.semanticVersion,
		environment: globalThis.environmentName,
		from: globalThis.launchedFrom
	} satisfies Partial<FirebaseAnalyticsDefaultEventParameters>

	// Backwards compatibility
	globalThis.defaultFirebaseAnalyticsEventParameters.trust ??=
		globalThis.defaultFirebaseAnalyticsEventParameters.craftSiteHandle
	globalThis.defaultFirebaseAnalyticsEventParameters.role ??=
		globalThis.defaultFirebaseAnalyticsEventParameters.craftSectionHandle

	// Stuart's experimenting
	globalThis.trustSelection = globalThis.defaultFirebaseAnalyticsEventParameters.trust ?? null
	globalThis.roleSelection = globalThis.defaultFirebaseAnalyticsEventParameters.role ?? null

	setDefaultEventParameters(globalThis.defaultFirebaseAnalyticsEventParameters)
	if (globalThis.firebaseAnalytics !== undefined)
		setUserProperties(globalThis.firebaseAnalytics, globalThis.defaultFirebaseAnalyticsEventParameters)

	// https://developers.google.com/tag-platform/gtagjs/reference
	if ("gtag" in globalThis && typeof globalThis.gtag === "function") {
		globalThis
			.gtag("set", "user_properties", globalThis.defaultFirebaseAnalyticsEventParameters)
			// eslint-disable-next-line promise/prefer-await-to-then
			.catch((error: unknown) => {
				console.warn(`Failed to set user properties via gtag.js! (${error?.toString() ?? "N/A"})`)
			})

		// eslint-disable-next-line promise/prefer-await-to-then
		globalThis.gtag("set", globalThis.defaultFirebaseAnalyticsEventParameters).catch((error: unknown) => {
			console.warn(`Failed to default event parameters via gtag.js! (${error?.toString() ?? "N/A"})`)
		})
	}

	console.info(
		`Updated default analytics event parameters! (${JSON.stringify(globalThis.defaultFirebaseAnalyticsEventParameters)})`
	)
}

/**
 * Sends an event to Firebase/Google Analytics.
 * This only works when deployed.
 * @param name The name of the event.
 * @param parameters Optional parameters to send with the event.
 * @example logFirebaseAnalyticsEvent("select_content", { content_type: "...", content_id: "..." })
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.1.0
 */
export const logFirebaseAnalyticsEvent = <Name extends keyof FirebaseAnalyticsEvents>(
	name: Name,
	parameters?: FirebaseAnalyticsEvents[Name]
): void => {
	if (!import.meta.env.PROD) return // Don't send analytics during development

	const { firebaseAnalytics } = globalThis
	if (firebaseAnalytics === undefined) return

	console.info(`Logged analytics event '${name}' with parameters '${JSON.stringify(parameters)}'`)

	logEvent(firebaseAnalytics, name, parameters)
}

/**
 * Initialises Firebase/Google Analytics.
 * @example initializeFirebase()
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.1.2
 */
export const initializeFirebaseAnalytics = (): void => {
	if (!import.meta.env.PROD) return // Don't send analytics during development

	const firebaseApp = initializeApp({
		apiKey: FIREBASE_API_KEY,
		authDomain: FIREBASE_AUTH_DOMAIN,
		projectId: FIREBASE_PROJECT_ID,
		storageBucket: FIREBASE_STORAGE_BUCKET,
		messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
		appId: FIREBASE_APP_ID,
		measurementId: FIREBASE_MEASUREMENT_ID
	})

	updateFirebaseAnalyticsDefaultEventParameters()

	globalThis.firebaseAnalytics = getAnalytics(firebaseApp)
	logEvent(globalThis.firebaseAnalytics, "initialise")
}
