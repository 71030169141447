import { motion, type HTMLMotionProps } from "framer-motion"
import type { JSX } from "react"
import { ReactSVG } from "react-svg"
import terminal from "virtual:terminal"

import BackgroundImage from "~/components/background-image"
import Citations from "~/components/citations"
import ContentBlocks from "~/components/content-blocks/content-blocks"
import ContentCard from "~/components/content-card"
import InnerError from "~/components/inner-error"
import Metadata from "~/components/metadata"
import QuickNavigation from "~/components/quick-navigation"
import SkeletonLoader from "~/components/skeleton-loader"
import { orNull } from "~/helpers/primitives"
import { useRouteCraftEntry } from "~/hooks/router/use-route-craft-entry"
import { useRouteCraftSection } from "~/hooks/router/use-route-craft-section"
import { useRouteCraftSite } from "~/hooks/router/use-route-craft-site"
import { BackgroundImagePath } from "~/images"
import { constructCraftAdminURL } from "~/router/routes"
import { CraftSeverity } from "~/types/api/craft/fields/severity"
import { isCraftHospitalEntry } from "~/types/api/craft/sections/hospitals"
import { isCraftParentsEntry } from "~/types/api/craft/sections/parents"
import { isCraftProfessionalsEntry } from "~/types/api/craft/sections/professionals"

import TickIcon from "~/assets/icons/tick.svg?react"
import WarningIcon from "~/assets/icons/warning.svg?react"

// https://vitejs.dev/guide/env-and-mode#env-variables-and-modes
const UPLOADS_BASE_URL = orNull(import.meta.env.VITE_UPLOADS_BASE_URL)
if (UPLOADS_BASE_URL === null) throw new Error("The Craft CMS uploads base URL is missing!")

/**
 * The page for rendering Craft CMS entries.
 * @returns The React component. This should only be used by the router.
 * @example <CraftEntryPage />
 * @author Jay Hunter <jh@yello.studio>
 * @since 0.1.0
 */
const CraftEntryPage = ({ ...props }: HTMLMotionProps<"main">): JSX.Element => {
	const craftSite = useRouteCraftSite()
	const craftSection = useRouteCraftSection(craftSite)
	const { entry, isReady, isError, error } = useRouteCraftEntry(craftSite, craftSection)

	// If the page only has a single image content block, we'll use it as the background image
	/*
	const imageForBackground = useMemo(() => {
		const imageContentBlocks = entry?.contentBlocks.filter(isImageContentBlock)
		if (imageContentBlocks === undefined || imageContentBlocks.length !== 1) return null

		const [imageContentBlock] = imageContentBlocks
		if (imageContentBlock === undefined) return null

		return imageContentBlock
	}, [entry])
	*/

	// Dump Craft CMS URL to the Vite console for ease of development
	if (craftSite !== null && craftSection !== null && entry !== null && import.meta.env.DEV)
		terminal.info(
			`We're on Craft CMS entry '${entry.title.toString()}' (${entry.id.toString()}) @ ${constructCraftAdminURL(
				craftSite.handle,
				craftSection.handle,
				entry.id,
				entry.slug
			)}`
		)

	return (
		<motion.main
			{...props}
			// key={
			// 	routerState?.partialEntry.entry_id ??
			// 	`${siteHandle?.toString() ?? "n/a"}-${roleHandle?.toString() ?? "n/a"}-${slugParameter?.toString() ?? "n/a"}`
			// }
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={`flex flex-grow flex-col justify-end overflow-y-auto ${props.className ?? ""}`.trimEnd()}>
			{/* eslint-disable-next-line no-nested-ternary */}
			{isReady && entry === null ? (
				<InnerError
					heading="Not Found"
					message="Sorry, this page does not exist. Please return to the previous page."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ChildWithSymptoms}
							accessibilityDescription="A parent checking on a child that has symptoms."
							className="h-[55dvh]"
						/>
					}
				/>
			) : isError && error !== null ? (
				<InnerError
					heading="API Error"
					message="Sorry, we're having problems communicating with our server. Please try again later."
					backgroundImage={
						<BackgroundImage
							imageUrl={BackgroundImagePath.ChildWithSymptoms}
							accessibilityDescription="A parent checking on a child that has symptoms."
							className="h-[55dvh]"
						/>
					}
					error={error}
				/>
			) : (
				<>
					{entry !== null && <Metadata title={entry.title} />}

					{/* Feature */}
					{/* <BackgroundImage
						imageUrl={imageForBackground?.image ?? BackgroundImagePath.ChildWithSymptoms}
						accessibilityDescription={
							imageForBackground?.imageTitle ?? "A parent checking on a child that has symptoms."
						}
					/> */}
					<BackgroundImage
						//ref={backgroundImageReference}
						imageUrl={BackgroundImagePath.ChildWithSymptoms}
						accessibilityDescription="A parent checking on a child that has symptoms."
						className="h-[55dvh]"
					/>

					{/* Card */}
					{/* <AnimatePresence mode="wait"> */}
					{isReady ? (
						<ContentCard
							// key={partialEntryId}
							heading={entry?.title ?? "Loading..."}
							severity={entry?.fields.severity ?? null}
							constrainDimensions={true}
							dynamicBackgroundImage={false}
							icon={
								/* eslint-disable no-nested-ternary */
								entry?.fields.icon?.url.endsWith(".svg") === true ? (
									<ReactSVG
										src={`${UPLOADS_BASE_URL}${entry.fields.icon.url}`}
										className="h-full w-full fill-white"
									/>
								) : entry?.fields.severity === CraftSeverity.Red ||
								  entry?.fields.severity === CraftSeverity.Amber ? (
									<WarningIcon className="h-full w-full fill-white" />
								) : entry?.fields.severity === CraftSeverity.Green ? (
									<TickIcon className="h-full w-full fill-white" />
								) : undefined
							}>
							<SkeletonLoader isLoading={entry === null} innerClassName="flex-col">
								{entry !== null && (
									<div className="flex flex-col gap-y-4">
										{(isCraftHospitalEntry(entry) ||
											isCraftParentsEntry(entry) ||
											isCraftProfessionalsEntry(entry)) && (
											<Citations citations={entry.fields.citations} />
										)}

										<ContentBlocks
											blocks={entry.fields.contentBlocks}
											severity={entry.fields.severity ?? undefined}
											entryTitle={entry.title}
											//ignoreImage={imageForBackground !== null}
										/>
									</div>
								)}
							</SkeletonLoader>

							<QuickNavigation />
						</ContentCard>
					) : (
						<ContentCard heading="Loading..." constrainDimensions={true} dynamicBackgroundImage={false}>
							<SkeletonLoader isLoading={true} innerClassName="flex-col" />
							<QuickNavigation />
						</ContentCard>
					)}

					{/* </AnimatePresence> */}
				</>
			)}
		</motion.main>
	)
}

export default CraftEntryPage
